import React, { useEffect, useState } from 'react';
import "./main.css"
import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import { AppHeaderComponent, CalendarApi } from "@unity/components";
import Loading from "./Loading"
export default function Calendar({ context }) {

    const [data, setData] = useState(false)

    const getEventsData = async () => {
        const res = await CalendarApi.getEventsByUser(context.auth.id)
        setData(res.data)
    }

    useEffect(() => {
        getEventsData()
    }, [])

    console.log(data)

    if (data) {
        return (
            <>
                <AppHeaderComponent theme={context.theme} context={context} name="calendar" />

                <div style={{ paddingTop: 180 }}>
                    <ScheduleComponent
                        selectedDate={new Date()}
                        eventSettings={{
                            dataSource: data,
                            fields: {
                                id: "Id",
                                subject: { name: "Subject" },
                                isAllDay: { name: "IsAllDay" },
                                startTime: { name: "StartTime" },
                                endTime: { name: "EndTime" },
                            },
                        }}
                    >
                        <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
                    </ScheduleComponent>;
                </div>
            </>
        )
    } else {
        return <Loading />
    }
}